/* eslint-disable consistent-return */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import CustomLink from 'components/CustomLink'
import ParseContent from 'components/ParseContent'

// CSS
import 'styles/Blog.scss';

// Components
import BlogErvaringHeader from 'components/PreviewContainer/BlogErvaringHeader'
import Specialist from 'components/Specialist'

// Third Party
import parse from 'html-react-parser'

export const ActieTemplate = (
  {
    title,
    blogImage,
    content,
    specialist,
    h1Tag
  }) => {

  const location = ''

  return (
    <section className="section blog-detail">
      <h1 className="d-none">{parse(h1Tag)}</h1>
      <div className="blog-detail-thin-line d-lg-block d-none color-background-contrast" />
      <BlogErvaringHeader className="pb-5 pb-lg-0" image={blogImage} title={title} location={location} />
      <div className="blog-detail-thin-line d-lg-block d-none color-background-secondary" />
      <div className="container blog-container">
        <div className="row">
          <div className="col-12 pt-3 pb-2 back-to-blog">
            <CustomLink to="/acties" className="font-weight-xl font-size-sm">&gt; Terug naar actieoverzicht</CustomLink>
          </div>
          <div className="col-12 col-lg-8">
            <div className="pt-2 pb-5 blog-detail-content">
              <ParseContent content={content} />
            </div>
          </div>
          <div className="col-12 col-lg-4 pt-2 d-flex justify-content-center justify-content-lg-end">
            <Specialist specialist={specialist} />
          </div>
        </div>

        <div className="empty-space-100" />
      </div>
    </section>
  )
}

const Blog = ({ data }) => {

  const { wordpressWpActie: actie } = data

  return (
    <Layout>
      {actie.acf.blog_content.image.localFile.childImageSharp.fixed.src ? (
        <SEO yoast={{ meta: actie.yoast_meta }} pathname={`/acties/${actie.slug}`} image={actie.acf.blog_content.image.localFile.childImageSharp.fixed.src} article />
      ) : (
        <SEO yoast={{ meta: actie.yoast_meta }} pathname={`/acties/${actie.slug}`} article />
      )}
      <ActieTemplate
        title={actie.title}
        blogImage={actie.acf.blog_content.image.localFile.childImageSharp.fluid}
        content={actie.acf.blog_content.story}
        specialist={actie.acf.blog_content.specialist}
        h1Tag={actie.acf.blog_content.h1_tag}
      />
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  fragment ActiePostFields on wordpress__wp_nieuws {
    id
    slug
  }
  query DetailActieQuery($id: String!) {
    wordpressWpActie(id: {eq: $id}) {
      title
      slug
      yoast_meta {
        content
        name
        property
      }
      acf {
        blog_content {
          small_introduction
          story
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                fixed(width: 1024, toFormat: JPG) {
                  src
                }
              }
            }
          }
          specialist {
            title
            slug
            acf {
              number
              specialist_text
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 250) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          h1_tag
        }
      }
    }
  }
`
